/* Base Css Start  */
/* Custom Fonts Linking  */
@font-face {
  font-family: 'Metropolis Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Regular'), url('../public/assets/fonts/Metropolis-Regular.woff') format('font-woff');
}

body {
  background-color: #F6F7F9;
}

.App {
  text-align: center;
}

.App-logo {

  pointer-events: none;
  width: 40px;
  height: 40px;
}

.menu-item {
  padding: 10px;
}

.package-item-selected {
  flex: 1 0 200px !important;
  border-color: #83C25F;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 30px; */
  padding: 10px;
  background: #F6F7F9;
  height: 56px;
  /* width: 28%; */
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  text-align: justify;

  /* Text/Text */

  color: #23262F;
}

.package-item img {
  width: 50px;
}

.package-item-selected img {
  width: 50px;
}

.package-item {
  flex: 1 0 200px !important;
  border-style: solid;
  border-color: #F6F7F9;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 30px; */
  padding: 10px;
  background: #F6F7F9;
  height: 56px;
  /* width: 28%; */
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  text-align: justify;

  /* Text/Text */

  color: #23262F;
}

.package-item img {
  width: 50px;
}

.packages {
  margin: 4px;
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px;
  margin-bottom: 0px;
  cursor: pointer;
}

.results {
  margin-top: 0px;
  padding-top: 0px;
}

.outer-box {
  margin-top: 100px;
  background-color: white;
  color: black;
  display: flex !important;
  width: 90%;
  justify-content: space-around;
  flex-direction: column;
  max-width: 850px;
}

.package-result-item {
  padding: 20px;
  background-color: #F6F7F9;
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px;
}

.package-result-item img {
  width: 100px;
}

.package-result-details {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */


  /* Text/Text */

  color: #23262F;
  cursor: pointer;
}

.package-result-details2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 10px;
  /* identical to box height */


  /* Text/Text */

  color: #23262F;
}

.package-result-details span {
  /* Subtitle/16 SB */

  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  /* Text/Text secondary */

  color: rgba(35, 38, 47, 0.6);

}

.package-result-name-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
  /* identical to box height */

  text-align: justify;

}

@media only screen and (max-width: 520px) {
  .package-result-name-details {
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

  }
}

.name-details-producer {
  display: flex;
  flex: 1;
}

.name-details-star-rating {
  flex: 1;
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 520px) {
  .package-result-name-details {
    flex-direction: column;
    gap: 15px;
    /* margin-bottom: 10px; */
  }
  .name-details-producer {
    text-align: center;
    justify-content: center;
  }
  .name-details-star-rating {
    justify-content: center;
  }
}

.package-result-description {
  margin-top: 10px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: justify;
  color: #23262F;
}

.package-result-price {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  text-align: right;

  /* Text/Text */

  color: #23262F;
}

.package-result-additional {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.package-result-additional-items img {
  width: 26px;
  background-color: rgba(131, 194, 95, 0.5);
  margin: 1px;
  padding: 4px;
}

.loading {
  height: 100%;
  width: 100%;
  background-color: #61dafb;
}

.no-packages-found {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.package-details-wrap {
  padding: 20px;
  background-color: white;
  border-radius: 2px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 850px;
}

.package-details-item {
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex: 1;
  flex-direction: row;
  /* margin: 50px; */
  margin-bottom: 5px;
}

@media only screen and (max-width: 520px) {
  .package-details-item {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 520px) {
  .package-details-item {
    flex-direction: column;
  }
}

.producer-title {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23262F;
}



.package-details-item img {
  width: 100px;
}

.garden-info-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 50px;
  margin-right: 50px;
  gap: 20px;
}

@media only screen and (max-width: 520px) {
  .garden-info-wrap {
    margin: 0;
  }
}


.garden-price {
  margin-top: 15px;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23262F;
}

.garden-price span {
  margin-top: 15px;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #23262F;
}

.garden-price-eur {

  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6);
  /* margin-top: -5px; */
  margin-bottom: 10px;
  text-align: right;

}

.garden-price-note {
  margin: 50px;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: justify;
  font-weight: 500;
  font-size: 10px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: rgba(35, 38, 47, 0.6);
}

.garden-info {



  margin-bottom: 30px;

  /* Headline/20 SB */

  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */


  /* Text/Text */

  color: #23262F;
}

@media only screen and (max-width: 520px) {
  .garden-price-eur, .garden-price {
    text-align: center;
  }
  .garden-price {
    padding-top: 10px;
  }
  .garden-price-eur {
    padding-bottom: 10px;
  }
  .garden-price-note {
    margin: 0;
    padding-bottom: 40px;
  }
  .garden-info {
    margin: 0;
  }
}

.package-result-img {
  /* width: 150px;
  height: 150px; */

  /* margin-right: 20px; */
  flex: 1;
  background: #F6F7F9;
  border-radius: 2px;
  cursor: pointer;
}

.package-result-img3 {
  /* width: 150px;
  height: 150px; */

  /* margin-right: 20px; */
  flex: 1;
  border-radius: 2px;
  cursor: pointer;
}

.package-result-img2 {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  background: #F6F7F9;
  border-radius: 2px;
}

.package-result-img img {
  width: 150px;
  height: 150px;
}

.package-result-img3 img {
  width: 150px;
  height: 150px;
}

@media only screen and (max-width: 520px) {
  .package-result-img img {
    width: 250px;
    height: 250px;
  }

  .package-result-img3 img {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 440px) {
  .package-result-img img {
    width: 180px;
    height: 180px;
  }

  .package-result-img3 img {
    width: 225px;
    height: 225px;
  }
}

.package-result-img2 img {
  width: 150px;
  height: 150px;
}

.garden-location-details {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

@media only screen and (max-width: 440px) {
  .garden-location-details {
    flex-direction: column;
  }
}

.garden-location {
  width: 150px;
  font-weight: 700;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #23262F;
}

.garden-location-data {
  width: 75%;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: justify;
}

@media only screen and (max-width: 520px) {
  .garden-location-data {
    width: 100%;
    text-align: left;
  }
}

.garden-location-data span {
  width: 75%;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  /* Text/Text secondary */

  color: rgba(35, 38, 47, 0.6);
}

.additional-elements-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.garden-details-additional-elements {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40%;
}

.garden-details-additional-elements-text {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #23262F;
}

.add-to-basket {
  display: flex;
  margin-top: 70px;
  justify-content: center;
}

.garden-details-button {
  background-color: #83C25F;
  color: white;
  padding: 12px 127px;
  border-style: none;
  border-radius: 2px;
}

@media only screen and (max-width: 520px) {
  .garden-details-button {
    flex: 1;
    padding: 20px;
  }
}

.garden-details-button:hover {
  background-color: #77B951;
  cursor: pointer;
}

.body-wrapper {
  width: 100%;
  height: 100%;
  margin: 15px;
  margin-top: 100px;
}

.body-wrapper-cart {
  flex: 1;
  height: 100%;
  margin: 15px;
  margin-top: 100px;
}

.outer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.basket-wrapper {

  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;

}

.basket-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #F6F7F9;
  margin-top: 20px;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.basket-image img {
  width: 64px;
  height: 64px;
  background-color: green;
}

.basket-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.basket-increment-box {
  width: 25px;
  height: 25px;
  background-color: white;
  color: #23262F;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
  border-style: none;
}

.basket-summary-wrapper {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}


@media only screen and (max-width: 520px) {
  .body-wrapper-cart {
    width: 100%;
  }
  .outer-wrapper {
    width: 100% !important;
    flex-direction: column;
  }
  .basket-wrapper {
    max-width: 100%;
  }
}

.basket-summary-text {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #23262F;
}

.basket-summary-text span {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: rgba(35, 38, 47, 0.6);
}

.separator {
  width: 100%;
  height: 2px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #F6F7F9;
}

.basket-base-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
}

.basket-vat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.basket-summary-label {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #23262F;
}

.basket-summary-gray-label {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6);
  margin-top: 4px;
  margin-bottom: 10px;
  text-align: right;
}

.basket-user-data-label {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6);
  text-align: right;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.basket-summary-bold-label {
  min-width: 20px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #23262F;
}

.basket-final-price {
  display: flex;
  justify-content: space-between;
}

.basket-order-button {
  align-items: center;
  width: 290px;
  height: 40px;
  background: #83C25F;
  border-radius: 2px;
  border-style: none;
  color: white
}

@media only screen and (max-width: 600px) {
  .basket-order-button {
    width: 100% !important;
  }
}

.basket-order-button:hover {
  background-color: #77B951;
  cursor: pointer;
}

.basket-input {
  background: #FFFFFF;
  border: 2px solid #EFF0F1;
  border-radius: 2px;
}

.basket-input:focus {
  border: 2px solid #83C25F;
  border-radius: 2px;
  outline: none;
  transition: ease-in-out, ease-in-out;
}
@media only screen and (max-width: 600px) {
  .basket-user-data-label {
    flex: 1;
  }
  .basket-user-input-div {
    width: 100%;
  }
  .basket-input {
    width: 100%;
  }
}

.basket-user-details {
  display: flex;
  justify-content: flex-start;
  border-style: single;
  border-width: 2px;
  border-color: red;
  align-items: center;
  gap: 10px;
  ;
}

@media only screen and (max-width: 520px) {
  .basket-user-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* justify-content: end;
    align-items: start; */
  }
}

.user-details {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.payment-types {
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.extra-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.circle {
  width: 13px;
  height: 13px;
  border-radius: 20px;
  border-width: 3px;
  border-color: #EFF0F1;
  border-style: solid;
  float: left;
  margin-right: 10px;


}

.active1 {
  background-color: #83C25F;
}

.error {
  border-color: #E16351 !important;
  border-width: 1px;
}

.checkbox-error {
  height: 10px;
  width: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 1px solid #E16351 !important;
  border-radius: 2px;
  ;
}

.basket-user-details-wrapper {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 520px) {
  .basket-user-details-wrapper {
    flex-direction: column;
  }
}

.success-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-top: 100px;
  padding-top: 150px;
  padding-bottom: 150px;
  width: 50%;
  height: 40%;
  background-color: white;
}

.success-messagebox {
  padding: 20px;
  color: #23262F;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: rgba(35, 38, 47, 0.6);
}

@media only screen and (max-width: 600px) {
  .success-wrapper {
    width: 100%;
  }
}

.success-title {
  padding: 20px;
  color: #23262F;
  text-align: center;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #23262F;
}

.dropdown {
  margin-top: 20px;
  word-wrap: break-word;
  max-width: 200px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6) !important;
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.Dropdown-control {
  word-wrap: break-word;
  max-width: 200px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6) !important;
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #77B951;
}

.Dropdown-option:hover {
  background-color: #77B951 !important;
  color: white !important;
}

.Dropdown-option {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6) !important;
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.Dropdown-option.is-selected {
  background-color: #77B951 !important;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: white !important;
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.stars {
  display: flex;
  gap: 2px;
}

.stars img {
  width: 20px;
  height: 20px;
}

.loader {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 50px;
  height: 50px;
}

/* .dropdown-item:hover {
  background-color: #77B951;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(2px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.basket-terms {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.basket-terms a {
  margin-left: 4px;
  color: #83C25F;
  text-decoration: none;
}

.checkbox {
  display: flex;
  margin-right: 5px;
  ;
}

.no-success {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding-top: 5px;
  color: #E16351;
}

.price-wrapper {
  display: flex;
  flex-direction: column;
}

.eur-price {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(35, 38, 47, 0.6);
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.cards {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .cards {
    flex-wrap: wrap;
  }

  .cards:nth-child(2) img {
    width: 100px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}